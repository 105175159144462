<template>
  <a-form
    :model="fromState"
    name="basic"
    autocomplete="off"
    @finish="onFinish"
    @finishFailed="onFinishFailed"
    @submit="onSubmit"
  >

<a-form-item v-for="item in layout"
             :key="item.name"
             :label="$t('field.'+item.name)"
             :name="item.name"
             :rules="[{ required: (item.editable && (!item.null || !item.blank)), message: 'Please input this field.' }]"
             v-bind="formItemLayout">
  <span v-if="!item.editable">{{fromState[item.name]}}</span>
  <a-date-picker v-else-if="utils.table.datatype_is_datetime(item)" v-model:value="fromState[item.name]" show-time :allowClear="false"/>
  <a-input-number v-else-if="utils.table.datatype_is_int(item)" :precision="0" v-model:value="fromState[item.name]" />
  <a-input-number v-else-if="utils.table.datatype_is_float(item)" v-model:value="fromState[item.name]"/>
  <a-select v-else-if="utils.table.select_cell(item)" v-model:value="fromState[item.name]"
            :options="utils.dom.select_options(item, null,false)"
            :filter-option="utils.dom.select_filterOption"
            show-search
            style="width: 100%;"/>
  <a-input v-else v-model:value="fromState[item.name]" />
</a-form-item>

    <a-form-item label="&nbsp;" :colon="false">
      <a-row>
        <a-col :span="20">
          <a-space>
            <a-button :disabled="isSaving" type="primary" name="save_close" html-type="submit">
              <template #icon><FormOutlined/></template>
              {{bnSubmitText}} & 关闭
            </a-button>

            <a-button :disabled="isSaving" name="save_edit" html-type="submit">
              <template #icon><EditOutlined/></template>
              {{bnSubmitText}}
            </a-button>

            <a-button @click="onBnClose">
              <template #icon><CloseOutlined /></template>
              关闭
            </a-button>

          </a-space>
        </a-col>
        <a-col :span="4" style="text-align: right;">
          <a-popconfirm  v-if="fromState.id" title="确定删除记录?" @confirm="onDelete">
            <a-button danger>
              <template #icon><DeleteOutlined /></template>删除
            </a-button>
          </a-popconfirm>
        </a-col>
      </a-row>

    </a-form-item>

  </a-form>




</template>
<script>
import {inject, defineComponent, computed, ref, reactive, onMounted } from 'vue';
import {FormOutlined, EditOutlined, DeleteOutlined, CloseOutlined} from '@ant-design/icons-vue';


export default defineComponent({
  components: {
    FormOutlined,
    EditOutlined,
    DeleteOutlined,
    CloseOutlined,
  },

  props:{
    app: {type: String, default: ''},
    model: {type: String, default: ''},
    report_name: {type: String, default: ''},
    filters: {type: Object, default: ()=>({})},
    sorting: {type: Object, default: ()=>({})},
    allow_add: {type: Boolean, default: false},
    show_history: {type: Boolean, default: false},
    parent_keys: {type: Object, default: ()=>({})},
    header_visible: {type: Boolean, default: false},
    header_buttons: {type: Array, default: ()=>([])},
    header_switches: {type: Array, default: ()=>([])},
    header_bn_refresh: {type: Boolean, default: true},
    header_sw_history: {type: Boolean, default: false},
  },

  setup(props) {
    const utils = inject('utils')
    let moment = inject('moment');
    let message = inject('message');
    const fromState = reactive({
      id:0,
    });

    const layout = ref([]);

    const formItemLayout = {
      labelCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 4,
        },
      },
      wrapperCol: {
        xs: {
          span: 24,
        },
        sm: {
          span: 20,
        },
      },
    };

    let submitBnName = '';
    const isSaving = ref(true);

    const bnSubmitText = computed(() => {
      if (fromState.id){
        return "保存";
      }else{
        return '新建';
      }
    })

    onMounted(() => {
      let pk = utils.router.query_id;
      // if (!pk){
      //   fromState.user = user.id;
      //
      //   isSaving.value = false;
      //   utils.dom.page_title(document, '新建')
      //   return;
      // }

      utils.api.get_obj(props.app, props.model, pk)
          .then(response =>{
            console.log(response.data)
            utils.dict.sync_for_DOM(fromState, response.data, moment)
            delete fromState[layout]
            // utils.dom.page_title(document, eventState.subject)
            isSaving.value = false;
            layout.value = response.data.layout
          })
          .catch(()=>{
            message.warning('server error')
          })
          .finally(()=>{
            // message.warning('server error')
          })
    })

    function load_data(response){
      utils.dict.sync_for_DOM(fromState, response.data, moment)
      utils.dom.page_title(document, fromState.code)
      isSaving.value = false;
    }

    const onSubmit = (e) => {
      isSaving.value = true;
      submitBnName = e.submitter.name;
    };

    const onFinish = () => {
      utils.api.save_obj(props.app, props.model,fromState)
          .then((response)=>{
            message.success('已保存')

            if (submitBnName === 'save_close'){
              utils.router.go_back();
              return;
            }
            if (fromState.id){
              load_data(response);
            }else{
              utils.router.edit_asset(response.data.id)
            }
          })
          .catch(err=>{
            console.log(err.response.data)
            message.error(err.response.statusText);
          })
          .finally(()=>{
            submitBnName = ''
            isSaving.value = false;
          })
    };


    function onDelete() {
      if (fromState.id){
        utils.api.del_obj(props.app, props.model,fromState.id)
          .then(()=>{
            message.info('已删除')
            utils.router.go_back();
          })
          .catch(()=>{
            message.error('删除时出错')
          })
      }else {
        utils.router.go_back();
      }
    }

    function onBnClose(){
      utils.router.go_back();
    }

    const onFinishFailed = () => {
      isSaving.value = false;
       // message.error('Failed:' + errorInfo)
    };





    return {
      utils,
      fromState,
      onSubmit,
      onFinish,
      onFinishFailed,
      onDelete,
      onBnClose,
      bnSubmitText,
      isSaving,
      layout,
      formItemLayout,
    };
  },


});
</script>

<style lang="less">
.full-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
  .ant-modal-content {
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-body {
    flex: 1;
  }
}
</style>